@use "./defaultSettings.scss" as ds;

.barColor{
    background-color: ds.$navbar-color;
}

.logo{
  display: flex;
  flex-direction: column;
  align-items: center;
  width:70%;
    height:100%;
}
.logoRight{
    display: none;
    flex-direction: column;
    align-items: center;
    margin:15px auto;
    width:90%;
      height:100%;
  }

  .logoMRight{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:20px auto;
    width:80%;
      height:100%;
      margin-right:0px;
  }

  .logoDivLeft{
    float:left;
}

.logoDivRight{
    float:right;
}
.logoDivMRight{
    float:right;
}

.toolbar{
    justify-content: space-between;
    padding-left:20px;
    padding-right:20px;
}

@media screen and (min-width: 660px){
    .logo{
        width:250px;
        height:100%;
        margin:20px auto;
    }
    .logoRight{
        display:flex;
        width:300px;
        height:100%;
        margin:20px auto;
    }
    .logoMRight{
        display:none;
    }
    .logoDivMRight{
        display:none;
    }

}
@media screen and (max-width: 660px){
    .logo{
        display:none;
    }
    .logoDivLeft{
        display:none;
    }
    .logoRight{
        display:none;
    }
    .logoDivRight{
        display:none;
    }
    .logoMRight{
        display:flex;
        width:250px;
        height:100%;
        margin:20px auto;
    }

    .toolbar{
        justify-content: center !important;
        padding-left:20px;
        padding-right:20px;
    }
}

