$button-background-color: #4FBAAD;
$button-text-color: #ffffff;
$red-button-text-color: #ffffff;
$card-button-background-color: #718bb8;
$inputs-background-color: #E3E4E5;
$default-font-color: #000000;
$default-link-color: #075599;
$hover-link-color: #eb6961;
$warning-font-color: #eb6961;
$default-font-type: 'Lato',helvetica,arial,sans-serif;
$default-button-font-size: 18px;
$default-heading-font-size: 18px;
$default-font-size: 16px;
$default-smaller-font-size: 14px;
$navbar-color: #153050;
$navbar-hover: #4FBAAD;
$deny-button-color: #eb6961 ;
$system-green: #4FBAAD;
$login-page-center-bar: $button-text-color;

$table-line-color: #e3e6f0;

$asterisk-color: #eb6961;

$checkbox-radiobox-background-color: #E3E4E5;
$checkbox-active-color: #eb6961;
$radiobox-active-color: #eb6961;

$carousel-stroke-color: #000000;
$carousel-inactive-color: #000000;
$carousel-active-color: #fff;

$photoUpload-image-size: 201px;
$photoUpload-button-size: 201px;

$horizontal-spacing-between-columns: 20px;

$back-button-background-color: #E3E4E5;

$textarea-background-color: #E3E4E5;
$textarea-font-color: #000;