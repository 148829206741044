@use "./defaultSettings.scss" as ds;
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;0,400;0,700;&display=swap');

//All user inputs
input, select, option {
    border: 0px; //Make the input look flat
    background-color: ds.$inputs-background-color;
    color: ds.$default-font-color;
}

//All buttons
input[type="submit"]{
    text-align: center;
    font-size: ds.$default-font-size;
    font-weight: normal;
    color: white;
    background-color: ds.$button-background-color;
    cursor: pointer;
    border: 0px;
}
select:disabled {
        opacity: 1;
}
:-webkit-autofill,
:-webkit-autofill:hover, 
:-webkit-autofill:focus{
    //Used to remove the autofill background Yellow colour
    -webkit-box-shadow: 0 0 0 30px ds.$inputs-background-color inset;
    //Used to remove the autofill black text colour
    -webkit-text-fill-color: ds.$default-font-color;
}

//All text - Mainly used for titles and names of inputs
html, body{
    font-size: ds.$default-font-type;
    color: ds.$default-font-color;
}
*{
    font-family: ds.$default-font-type;//Stops user agent stylesheet
}
//Required fields
.asterisk{
    color: ds.$asterisk-color;
}
a:-webkit-any-link, a:-webkit-any-link:active{
    color: ds.$default-font-color;
    &:hover {
        color: ds.$hover-link-color;
    }
}

.filepond--credits{
    color:black !important;
}

//Used for descriptive text
p{
    font-size: ds.$default-font-size;
    text-align: left;
}
//To seek out new life and civilisations
.boldText{
    font-weight: bold;
}
.small{
    font-style: italic;
    font-size: ds.$default-font-size;
    font-weight: bold;
    text-align: left;
}

h1, th {
    font-size:26px;
    font-weight:normal;
    margin-top: 20px;/*Without this, a default margin is imposed by the user style sheet. The margin pushes then entire body and html tag 10px down from the top of the page*/
    padding-top: 10px;/*Used to replace the margin*/
    margin-left:20px;
    margin-right:20px;
    margin-bottom:10px;
    text-align: left;
    
}
th {
    font-size:ds.$default-heading-font-size;
    font-weight:bold;
    margin-top: 0px;/*Without this, a default margin is imposed by the user style sheet. The margin pushes then entire body and html tag 10px down from the top of the page*/
    padding-top: 10px;/*Used to replace the margin*/
}
h2 {
    font-size: ds.$default-heading-font-size;
    font-weight: bold;
    text-align: left;
}
/*@author: Cal. This is used to display the required field key.*/
h4 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 20px;
    text-align: left;
    letter-spacing: 0.03333em;
    line-height:1.66;
}
textarea {
    border: 0px;
}
#backButton{
    background-color: ds.$back-button-background-color;
}
#navNextButton{
    background-color: ds.$button-background-color;
    color:white;
    max-width:660px;
    line-height:1.5;
}
#navBackButton{
    background-color: ds.$back-button-background-color;
    max-width:660px;
    line-height:1.5;
}



textarea {
    border: 0;
    background-color: ds.$textarea-background-color;
    color: ds.$textarea-font-color;
    resize: none;
    font-family: ds.$default-font-type;
}

.clickable {
    cursor: pointer;
}

/*To make datepickers appear on top of other elements*/
ui-datepicker-div {
    z-index: 1000;
}
.hidden {
    display: none;
}

.ui-dialog .ui-dialog-buttonset > button.alwaysVisible  {
    display: block;
    margin: 0 auto;
}



#outlined-basic-helper-text{
    margin-left:90%

}

#photoText{
    margin:10px auto;
    font-size:14px;
    text-align: left;
}

#infoText{
    margin:10px 0px;
    font-size:14px;
    text-align: left;;
}

#emailLoginStyle{
    padding-top:0px;
    margin-top:0px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}