@use "./defaultSettings.scss" as ds;

.btn{
    width:90%;
    margin:20px auto;
    text-transform:none;
    height:70px;
    color:#fff;
    font-weight:normal;
    font-size:20px;
  }
  


  .nextBtn{
    width:44%;
    float:right;
    text-transform:capitalize;
    margin-left:10px;
    height:70px;
    color:#fff;
    font-weight:normal;
    font-size:20px;
  }

  .nextSingleBtn{
    width:44%;
    float:right;
    text-transform:capitalize;
    height:70px;
    color:#fff;
    font-weight:normal;
    font-size:20px;
  }



.backBtn{
    background-color: ds.$back-button-background-color;
    color:black;
    width:44%;
    float:left;
    text-transform:capitalize;
    margin-left:25px;
    height:70px;
    font-weight:normal;
    font-size:20px;
  }

  .backBtn:hover{
    background-color: #9e9fa0;
    color:white;
}


.backSingleBtn{
  background-color: ds.$back-button-background-color;
  color:black;
  width:44%;
  float:left;
  text-transform:capitalize;
  height:70px;
  font-weight:normal;
  font-size:20px;
}

.backSingleBtn:hover{
  background-color: #9e9fa0;
  color:white;
}


.cancelSingleBtn{
  background-color: ds.$warning-font-color !important ;
  margin:100px auto;
  color:white;
  width:44%;
  float:left;
  text-transform:capitalize;
  height:70px;
  font-weight:normal;
  font-size:20px;
}

.cancelSingleBtn:hover{
  background-color: ds.$warning-font-color !important ;
  color:white;
}


.buttonContainer {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
 
  font-size: calc(10px + 2vmin);
  color: ds.$button-background-color;
  max-width:500px;
  margin:0 auto;
}

.navbuttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  margin:0 auto;
  max-width:500px;
  margin-top:40px;
  margin-bottom:40px;
}

.singleButtonContainer{
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
 
  font-size: calc(10px + 2vmin);
  color: ds.$button-background-color;
  max-width:500px;
  margin:0 auto;
  margin-top:40px;
  margin-bottom:40px;
}

.bottomNavContainer{
  display:none;
  
}

@media screen and (max-width: 660px){
  .singleButtonContainer{
    display:none;
  }
  .navbuttons{
    display:none;
  }
  .bottomNavContainer{
    display:flex;
    margin-top:100px;
  }

}