@use "./defaultSettings.scss" as ds;
.App {
  text-align: center;
}

.logo {
  height: 500px;
  width:500px;
  pointer-events: none;
}

.header {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
 
  font-size: 26px;
  color: ds.$system-green;
  max-width:500px;
  margin:0 auto;
}

.field{
  width:90%;
  margin-top:0px;
  margin-bottom:0px;
}
.successIcon{
  font-size:5.5rem;
  margin-top:10px;
  margin-bottom:20px;
  display:flex;
  align-items: center;
}

.errorheader {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
 
  font-size: 26px;
  color: ds.$warning-font-color;
  max-width:500px;
  margin:0 auto;
}

.warning{

  background-color: #fff;

  display: flex;
  flex-direction: column;
 
  font-size: 20px;
  color: ds.$warning-font-color;
  max-width:500px;
  margin:0 auto;
  margin-top:20px;
}

.warningList{

  background-color: #fff;

  display: flex;
  flex-direction: column;
 
  font-size: 20px;
  color: ds.$warning-font-color;
  max-width:500px;
  margin:0 auto;
  margin-top:20px;
  white-space: pre-wrap;
}

.warningTop{
  background-color: #fff;

  display: flex;
  flex-direction: column;
 
  font-size: 20px;
  color: ds.$warning-font-color;
  max-width:500px;
  margin:0 auto;
  margin-top:20px;
}


.questionH1 {
  background-color: #fff;

  display: flex;
  flex-direction: column;
 
  font-size: calc(10px + 2vmin);
  color: ds.$system-green;
  max-width:500px;
  margin:0 auto;
  margin-top:10px;
}

.card{
  background-color: #fff;

  display: flex;
  flex-direction: column;
 
  font-size: calc(10px + 2vmin);
  color: #000;
  max-width:500px;
  margin-top:10px;
}


.questionH1Top {
  background-color: #fff;

  display: flex;
  flex-direction: column;
 
  font-size: calc(10px + 2vmin);
  color: ds.$system-green;
  max-width:500px;
  margin:0 auto;
  margin-top:18px;
}

.titleH1Top {
  background-color: #fff;

  display: flex;
  flex-direction: column;
 
  font-size: calc(10px + 2vmin);
  color: black;
  max-width:500px;
  margin:0 auto;
  margin-top:18px;
}

.list {
  background-color: #fff;

  display: flex;
  flex-direction: column;
 
  font-size: 20px;
  color: #000;
  max-width:500px;
  margin:0 auto;
  margin-top:10px;
}


.listul {
  padding-left: 20px;
  padding-right: 20px;
}

.listimg {
  width:75%;
}

.successText{
  font-size:18px;
}

.signedInText{
  font-size:20px;
  margin:20px auto;
  font-weight: bold;
}


.successHeader{
  background-color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: ds.$system-green;
  max-width:500px;
  margin:0 auto;
  margin-top:18px;
}

.errorHeader {
  background-color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: ds.$warning-font-color;
  max-width:500px;
  margin:0 auto;
  margin-top:18px;
}

.textField {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
 
  font-size: calc(10px + 2vmin);
  color: ds.$system-green;
  max-width:500px;
  margin:20px auto;
}

.radioGroup {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
 
  font-size: calc(10px + 2vmin);
  color: ds.$system-green;
  max-width:500px;
  margin:0 auto;
}

.progressBar {
  color: ds.$system-green;
  max-width:500px;
  margin:10px auto;
  text-align: center;
}


.bold{
  font-weight: bold;
}


.bottomDiv{
  text-align: center;
  margin: 10px 5px 10px 5px;
}

.bottomParagraph{
  text-align:center;
}

@media screen and (max-width: 660px){
  .progressBar {
    color: ds.$system-green;
    max-width:320px;
    margin:0 auto;
  }
 
  .bottomDiv{
    text-transform: none;
    margin: 10px 30px 10px 30px;
  }

}